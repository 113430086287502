html {
    scroll-behavior: smooth;
    scroll-padding-top: 80px;
}

@keyframes slideInFromLeft {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownFromTop {
    from {
        opacity: 0;
        transform: translateY(-50%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUpFromBottom {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-50%);
    }
}

/* Header Alternative */

.header-tb-alt {
    width: 100%;
    height: auto;
    padding: 14px 0;
    background-color: #355682;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 100px;
}

.header-alt {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.header-alt-img-wrapper {
    position: relative;
    height: 70vh;
    width: 100%;
    overflow: hidden;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

.header-alt-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: blur(2px);
    background-color: #fff;
    opacity: 95%;
    transform: translateY(-3%);
    scale: 108%;
}

.header-alt-container {
    width: 70%;
    height: auto;
    margin: auto;
    display: flex;
}

.header-alt-contents {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.header-alt-headline {
    font-family: 'Montserrat', sans-serif;
    color: #fafafa;
    letter-spacing: 0.75px;
    font-weight: 600;
    font-size: 72px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin: auto;
    margin-top: 60px;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.75);
}

.header-alt-button-container {
    margin: auto;
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.header-alt-nevin-img-wrapper {
    position: absolute;
    bottom: 0;
    right: 5%;
    height: 103%;
    width: auto;
}

.header-alt-bottom-bar {
    height: 80px;
    width: 100%;
    background-color: #243852;
    text-align: center;
    z-index: 1;
    display: flex;
}

.header-alt-bottom-bar-sm,
.header-alt-bottom-bar-md {
    display: none;
}

/* Header */

.sold-pages-top-img-wrapper {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

.sold-pages-top-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(2px);
    background-color: #fff;
    opacity: 95%;
    transform: translateY(4%);
    scale: 100%;
}

.sold-pages-top-nevin-img-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 85%;
    width: auto;
    margin-bottom: 25px;
}

.header-top-bar {
    top: 0;
    margin-top: 100px;
    height: 40px;
    width: 100%;
    position: absolute;
    background-color: #355682;
    text-align: center;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

.header-bottom-bar {
    position: absolute;
    bottom: 0;
    height: 60px;
    width: 100%;
    background-color: #243852;
    text-align: center;
    z-index: 1;
    display: flex;
}

.header-bottom-bar-sm {
    display: none;
}

.sold-pages-top-tb-text {
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.095);
}

.sold-pages-top-bb-text {
    font-weight: 600;
    font-size: 20px;
    margin-top: 15px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.095);
}

.red {
    color: #d42b1b;
}

.bigred {
    font-size: 30px;
}

.bigred-sm {
    display: none;
}

.remax {
    height: 20px;
    width: auto;
}

.remax-sm {
    display: none;
}

.sold-pages-top-container {
    height: 100%;
    width: 70%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 4%;
    text-align: center;
}

.sold-pages-top-contents {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 80%;
    margin: auto;
    margin-left: 60px;
    padding: 80px;
}

.sold-pages-top-top-text,
.sold-pages-top-headline,
.sold-pages-top-subheadline,
.sold-pages-top-bb-text,
.sold-pages-top-tb-text,
.sold-pages-top-button,
.sold-pages-hv-button {
    font-family: 'Montserrat', sans-serif;
    color: #fafafa;
    letter-spacing: 0.75px;
}

.sold-pages-top-headline {
    font-weight: 600;
    font-size: 72px;
    text-transform: uppercase;
    width: 80%;
    margin: auto;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.75);
}

sp-sold-properties .sold-pages-top-top-text {
    font-weight: 600;
    font-size: 22px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.095);
}

.sold-pages-top-button-container {
    width: 90%;
    margin: auto;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 40px;
}

.sold-pages-top-button {
    width: auto;
    min-width: 320px;
    height: 70px;
    min-height: 50px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    background-color: #fafafa;
    color: rgb(36, 56, 82);
    border: 2px solid #fafafa;
    padding: 10px 25px;
    border-radius: 3px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.75);
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.095);
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* Just Sold Section */

.sold-pages-ath-container {
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding-bottom: 120px;
}

.spath-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
}

.sold-pages-just-sold-headline {
    font-family: 'Montserrat', sans-serif;
    color: #d42b1b;
    letter-spacing: 0.75px;
    font-weight: 600;
    font-size: 72px;
    text-transform: uppercase;
    width: 80%;
    margin: auto;
    margin-bottom: 30px;
    text-align: center;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

.home-about-container {
    width: 85%;
    display: flex;
    margin: auto;
    margin-top: 20px;
}

.home-about-img-wrapper.slide-in {
    animation: slideInFromLeft 1s ease forwards;
}

.home-about-text-div.slide-down {
    animation: slideDownFromTop 1s ease forwards;
}

.home-about-img-wrapper {
    left: 0;
    height: 400px;
    width: 680px;
    /* box-shadow: 3px 3px 10px rgba(0, 0, 0, .2); */
    transition: all 1s ease;
}

.home-about-img {
    height: 100%;
    width: auto;
    display: none;
}

.home-about-text-div {
    margin-left: 40px;
}

.home-about-p-div {
    display: flex;
    margin-left: 40px;
}

.home-about-p-div li {
    text-wrap: nowrap;
}

.spath-pic-div {
    display: flex;
    width: 80%;
    margin: auto;
}

.sold-property-img {
    width: 80%;
    min-width: 800px;
    height: auto;
    justify-content: right;
}

.property-img {
    width: 10%;
    height: auto;
}

.home-about-h1,
.home-about-h3,
.home-about-p {
    font-family: "Montserrat", sans-serif;
    color: #333;
}

.home-about-h1 {
    font-size: 45px;
    text-transform: uppercase;
    font-weight: 400;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
    margin-bottom: 30px;
}

.home-about-h3 {
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 3px;
    text-shadow: .5px .5px 1px rgba(0, 0, 0, 0.075);
}

.home-about-p {
    font-size: 16px;
    margin-top: 20px;
    margin-left: 3px;
    text-shadow: .5px .5px 1px rgba(0, 0, 0, 0.075);
}

.home-about-contact-info-phone-license {
    display: flex;
}

.hacipl-left {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.hacipl-right {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.home-about-label {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 20px;
    text-shadow: .5px .5px 1px rgba(0, 0, 0, 0.075);
}

/* Market Comparison */

.market-comparison-lg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('../../../public/enclave-sign-1.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    display: flex;
    align-items: center;
    box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.095),
        0px 10px 20px rgba(0, 0, 0, 0.095);
    padding: 100px 0;
}

.market-comparison {
    display: none;
}

.mc-outer-container {
    height: auto;
    width: 80%;
    margin: auto;
    border-radius: 20px;
    padding: 80px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #fafafa;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.095);
}

.headline-h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 60px;
    text-align: center;
    color: #333;
    font-weight: 400;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
    text-transform: uppercase;
    width: 90%;
    margin: auto;
    margin-bottom: 40px;
}

.mc-container {
    display: flex;
    align-items: center;
}

.mc-container-lg-top,
.mc-container-lg-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    margin-top: 40px;
}

.mc-container-lg-sections {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
}

.mc-container-lg-name-div {
    text-align: center;
    margin-bottom: 40px;
}

.mc-left,
.mc-middle,
.mc-right {
    position: relative;
    padding: 20px;
}

.mc-middle::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: 1.5px;
    height: 100%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.25), transparent);
}

.mc-right::before {
    content: '';
    position: absolute;
    top: 0;
    left: 5px;
    width: 1.5px;
    height: 100%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.25), transparent);
}

.mc-lg-nre-left,
.mc-lg-nre-middle,
.mc-lg-nre-right,
.mc-lg-lm-left,
.mc-lg-lm-middle,
.mc-lg-lm-right {
    position: relative;
    padding: 20px;
    width: 33.33%;
}

.mc-lg-nre-middle::before,
.mc-lg-lm-middle::before {
    content: '';
    position: absolute;
    top: 20%;
    left: -20px;
    width: 1.5px;
    height: 30%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.25), transparent);
}

.mc-lg-nre-right::before,
.mc-lg-lm-right::before {
    content: '';
    position: absolute;
    top: 20%;
    left: 5px;
    width: 1.5px;
    height: 30%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.25), transparent);
}

.mc-name {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.75);
    text-transform: uppercase;
    font-weight: 600;
    padding: 0;
    margin: 0;
}

.mc-name-lg {
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.75);
    text-transform: uppercase;
    font-weight: 600;
    padding: 0;
    margin: 0;
}

.mc-fa-circle {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: #355682;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 30px;
}

.mc-fa {
    height: 40px;
    width: 40px;
    color: #fafafa;
}

.mc-stat {
    font-family: "Playfair Display", serif;
    font-size: 60px;
    color: rgba(0, 0, 0, 0.75);
    font-weight: 600;
    padding: 0;
    margin: 0;
}

.mc-stat-2 {
    font-family: "Playfair Display", serif;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.75);
    font-weight: 600;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 40px;
}

.mc-source {
    font-family: "Montserrat", sans-serif;
    font-style: italic;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.25);
    width: 80%;
    margin: auto;
    margin-bottom: 40px;
}

.mc-divider {
    height: 1px;
    width: 40%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
    margin: auto;
    margin-bottom: 40px;
}

.mc-divider-lg {
    height: 1px;
    width: 90%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.source-bottom {
    text-align: right;
    margin-top: 60px;
    margin-right: 100px;
}

/* Our Previous Enclave Sales */

.other-sold-properties {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #243852;
    padding: 80px 0 120px 0;
}

.sp-sold-properties {
    width: 80%;
    margin: auto;
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    border-radius: 20px;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.095);
}

.sp-sold-properties-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: auto;
}

.sp-sold-properties-map-divs {
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 20px;
    gap: 60px;
    align-items: flex-start;
}

.sold-property-img-wrapper {
    width: 90%;
    height: auto;
    display: flex;
    margin: auto;
    margin-bottom: 80px;
    align-self: flex-start;
}

.sp-sold-property-img {
    width: 100%;
    height: auto;
    border-radius: 3px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

.sp-sold-properties-map-right-div {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 0;
}

.sp-sold-properties-headline-text {
    font-family: "Montserrat", sans-serif;
    font-size: 36px;
    text-align: center;
    color: #333;
    font-weight: 400;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
    text-transform: uppercase;
}

.sp-map-blurb {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #777;
    width: 95%;
    margin: auto;
}

.previous-sales-button {
    border: 3px solid #333;
    border-radius: 3px;
    padding: 10px 20px;
    width: 400px;
    height: auto;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
    text-decoration: none;
    text-wrap: nowrap;
    text-align: center;
    font-size: 20px;
    transition: all 0.3s ease;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.095);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    margin: auto;
}

/* Our Sales Process */

.our-marketing {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 80px 0;
    background-color: aliceblue;
}

.om-main-container {
    width: 90%;
    height: auto;
    margin: auto;
    margin-top: 40px;
}

.om-main-top,
.om-main-bottom {
    width: 100%;
    height: auto;
    display: flex;
}

.om-main-bottom {
    flex-direction: row-reverse;
    margin-top: 60px;
    margin-bottom: 100px;
}

.om-main-top-left,
.om-main-bottom-left {
    width: 50%;
    display: flex;
    justify-content: right;
}

.om-main-top-left,
.om-main-bottom-right {
    position: relative;
    display: inline-block;
}

.om-main-bottom-left {
    flex-direction: column;
    padding-left: 10%;
    margin-top: -180px;
}

.om-main-top-right,
.om-main-bottom-right {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.om-os-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.om-os-div {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}

.om-img-overlay-top {
    position: absolute;
    bottom: 0;
    width: 70%;
    height: 15%;
    padding: 30px 20px;
    background: linear-gradient(to top, #243852, transparent);
    z-index: 2;
    margin-left: 15%;
}

.om-img-overlay {
    position: absolute;
    bottom: 0;
    width: 70%;
    height: 15%;
    padding: 30px 20px;
    background: linear-gradient(to top, #243852, transparent);
    z-index: 2;
    margin-left: 10%;
}

.bottom-overlay {
    margin-left: 35%;
}

.om-main-top-img {
    width: 70%;
    height: auto;
    margin: auto;
    z-index: 2;
    border-radius: 3px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    display: block;
    position: relative;
}

.om-main-bottom-img {
    width: 70%;
    height: auto;
    margin-left: 35%;
    margin-top: -180px;
    border-radius: 3px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    display: block;
    z-index: 1;
    position: relative;
}

.om-headline {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-size: 42px;
    color: #243852;
    margin-bottom: 30px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    width: 80%;
}

.om-blurb {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #777;
    width: 85%;
}

.om-img-overlay-text {
    font-family: "Playfair Display", serif;
    font-weight: 600;
    font-size: 32px;
    font-style: italic;
    color: #fafafa;
    margin: 0;
}

.om-button {
    height: 50px;
    width: auto;
    max-width: 400px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 600;
    background-color: rgb(36, 56, 82);
    color: #fafafa;
    border: 2px solid rgb(36, 56, 82);
    padding: 10px 15px;
    border-radius: 3px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.095);
    text-wrap: nowrap;
    letter-spacing: 0.75px;
    margin-top: 50px;
}

.video-div {
    width: 80%;
    height: auto;
    margin: auto;
    padding: 50px;
    background-color: #fafafa;
    border-radius: 20px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
    gap: 40px;
}

.video {
    width: 65%;
    height: auto;
    margin: auto;
    color: #fafafa;
}

.video-div-right {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 0;
}

.video-blurb {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #777;
    width: 90%;
    margin: auto;
}

/* Meet the Team */

.sold-pages-meet-the-team {
    display: flex;
    width: 85%;
    height: auto;
    gap: 40px;
    margin: auto;
    flex-direction: column;
    color: #333;
    padding: 80px 0 120px 0;
}

.mtt-headline-h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 60px;
    text-align: center;
    color: #333;
    font-weight: 400;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
    text-transform: uppercase;
}

.sp-mtt-bio-div {
    padding: 20px 0 40px 0;
    width: 80%;
    margin: auto;
}

.sp-mtt-bio-p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #333;
    font-size: 18px;
    padding: 0 20px 30px 20px;
    text-shadow: .5px .5px 1px rgba(0, 0, 0, 0.075);
}

.sp-mtt-bio-p:last-of-type {
    padding: 0 20px 0 20px;
}

.mtt-left-and-right-divs {
    display: flex;
    width: 100%;
    margin: auto;
}

.sp-meet-team-links-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: auto;
    gap: 60px;
}

.sp-meet-team-link-wrapper {
    font-family: "Montserrat", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    width: 100%;
    height: auto;
}

.sp-meet-team-img-wrapper {
    width: 350px;
    height: auto;
}

.sp-meet-team-img {
    width: 100%;
    height: auto;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.mtt-text-div {
    width: 50%;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
}

.mtt-name {
    font-family: "Playfair Display", serif;
    font-size: 36px;
    font-style: italic;
    width: 20%;
    margin-top: 20px;
    font-weight: 600;
}

.mtt-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 10px;
}

.mtt-button-wrapper {
    margin-top: 40px;
    width: 100%;
    display: flex;
}

.mtt-button {
    text-decoration: none;
    color: #333;
    text-transform: uppercase;
    border: 3px solid #333;
    padding: 10px 15px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    width: auto;
}

.mtt-button:hover {
    background-color: rgb(36, 56, 82);
    border-color: rgb(36, 56, 82);
    color: #fafafa;
}

.mtt-awards-div {
    width: 30%;
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: aliceblue;
    padding: 60px 40px 70px 40px;
    border-radius: 10px;
}

.sold-pages-h2 {
    font-family: "Montserrat", sans-serif;
    color: #333;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
    font-weight: 400;
    font-size: 45px;
    margin: 0 0 20px 0;
}

.sp-mtt-awards-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 90%;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #fff;
    box-shadow: 10px 5px 10px rgba(220, 204, 187, 0.3);
    /* border: 6px solid #333; */
    border-radius: 10px;
    padding: 40px 10px;
    margin-top: 40px;
}

.sp-mtt-awards-img-wrap {
    width: 50%;
    height: auto;
}

.sp-mtt-awards-img {
    width: 100%;
    height: auto;
}

.sp-mtt-awards-text-div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 40px;
    margin-top: 60px;
    width: 80%;
    font-family: "Montserrat", sans-serif;
}

.sp-mtt-awards-text {
    font-weight: 600;
    font-size: 20px;
}

/* Work with NRE Parallax */

.sold-pages-parallax {
    background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('../../../public/exterior-2.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.095),
        0px 10px 20px rgba(0, 0, 0, 0.095);
}

.sold-pages-parallax-content {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.sold-pages-parallax-content h1 {
    margin-bottom: 60px;
    width: 60%;
}

.sold-pages-parallax-button {
    border: 4px solid #fafafa;
    border-radius: 3px;
    padding: 10px 20px;
    width: auto;
    height: auto;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #fafafa;
    text-transform: uppercase;
    text-decoration: none;
    text-wrap: nowrap;
    font-size: 24px;
    transition: all 0.3s ease;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.095);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    margin-bottom: 40px;
}

.sold-pages-parallax-button:hover {
    background-color: #fafafa;
    color: rgb(36, 56, 82);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.095);
}

/* Footer */

.sp-footer-container {
    background-color: rgb(36, 56, 82);
    font-family: "Montserrat", sans-serif;
    color: #fafafa;
    padding: 80px;
    width: 100%;
    height: auto;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sp-footer-logo-wrapper {
    width: 300px;
    height: auto;
    margin-bottom: 80px;
}

.sp-footer-logo {
    width: 100%;
    height: auto;
}

.sp-footer-site-link-div {
    display: flex;
    width: 100%;
    text-align: center;
    margin: 0 auto 80px auto;
    gap: 20px;
}

.sp-footer-link {
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    text-wrap: nowrap;
    margin: 5px 10px;
    color: #fafafa;
    font-weight: 600;
}

.sp-footer-contact-address-div {
    display: flex;
    gap: 80px;
    width: 60%;
    margin: auto;
}

.sp-footer-contact-div,
.sp-footer-address-div {
    width: auto;
    display: flex;
    flex-direction: column;
    width: 50%;
}

.footer-br {
    display: none;
}

.sp-footer-label {
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.sp-footer-info-bold {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

.sp-footer-info {
    font-size: 18px;
}

.sp-footer-social-links-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}

.sp-footer-social-links {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sp-footer-social-wrapper {
    display: flex;
    height: 22px;
    width: 22px;
    margin: 10px 30px;
}

.sp-footer-social-icon {
    color: #fafafa;
    height: 28px;
    width: 28px;
    cursor: pointer;
}

.sp-footer-legal-and-credentials-div {
    display: flex;
    margin-top: 80px;
}

.sp-footer-compliance-logos-div {
    width: 150px;
    height: auto;
    left: 0;
    width: 50%;
}

.sp-footer-compliance-logos {
    width: 150px;
    height: auto;
}

.sp-footer-legal-div {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
    right: 0;
    justify-content: right;
    width: 50%;
}

.sp-footer-legal-p {
    font-size: 12px;
    color: #fafafa;
    text-align: right;
}

.footer-privacy-link {
    font-size: 12px;
    color: #9cd8eb;
    text-decoration: none;
    text-align: right;
}

@media (max-width: 1525px) {

    .sold-pages-top-nevin-img-wrapper {
        height: 75%;
        width: auto;
        margin-bottom: 25px;
    }

    .header-top-bar {
        height: auto;
        padding: 10px;
    }

    .header-bottom-bar {
        display: none;
    }

    .header-bottom-bar-md {
        position: absolute;
        bottom: 0;
        height: auto;
        width: 100%;
        padding: 10px;
        background-color: #243852;
        text-align: center;
        z-index: 1;
        display: flex;
        flex-direction: column;
    }

    .sold-pages-top-tb-text {
        margin-top: 0;
    }

    .sold-pages-top-bb-text {
        margin-top: 0;
    }

    .sold-pages-top-container {
        top: 3%;
    }

    .sold-pages-top-contents {
        margin-left: 0;
        padding: 0 80px;
    }

    .sold-pages-top-headline {
        font-size: 54px;
        width: 90%;
    }

    .header-alt-headline {
        font-size: 54px;
        width: 90%;
    }

    .sold-pages-top-button-container {
        flex-direction: column;
        margin-top: 10px;
        gap: 20px;
    }

    .sold-pages-top-button {
        width: auto;
        margin: auto;
    }

    .header-alt-headline {
        font-size: 60px;
        width: 100%;
        margin-top: 80px;
    }

    .header-alt-nevin-img-wrapper {
        right: 3%;
    }

    .header-alt-bottom-bar {
        display: none;
    }

    .header-alt-bottom-bar-md {
        height: auto;
        width: 100%;
        padding: 10px;
        background-color: #243852;
        text-align: center;
        z-index: 1;
        display: flex;
        flex-direction: column;
    }

    .home-about-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .home-about-img-wrapper {
        background-color: aliceblue;
        padding: 20px 80px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-bottom: 40px;
        width: auto;
        height: auto;
    }

    .home-about-text-div {
        margin-left: 0;
    }

    .home-about-p-div {
        width: 60%;
        margin: auto;
        margin-top: 20px;
    }

    .sold-property-img {
        width: 80%;
        min-width: 800px;
        height: auto;
        justify-content: right;
    }

    .property-img {
        width: 10%;
        height: auto;
    }

    .home-about-h1 {
        text-align: center;
    }

    .home-about-h3 {
        text-align: center;
    }

    .home-about-p {
        width: 80%;
        margin: auto;
        margin-top: 30px;
    }

    .home-about-contact-info {
        width: 60%;
        margin: auto;
        text-align: center;
    }

    .listing-button {
        margin: auto;
    }

    .mc-outer-container {
        width: 90%;
    }

    .mc-stat {
        font-size: 48px;
        margin-top: 10px;
    }

    .mc-stat-2 {
        font-size: 22px;
    }

    .mc-source {
        font-size: 12px;
        width: 90%;
    }

    .source-bottom {
        margin-top: 20px;
        margin-right: 60px;
    }

    .sp-sold-properties-map-divs {
        flex-direction: column;
        width: 90%;
        gap: 0;
    }

    .sold-property-img-wrapper {
        width: 80%;
    }

    .om-main-bottom {
        margin-top: 120px;
    }

    .om-img-overlay-top {
        padding: 30px 20px 50px 20px;
    }

    .om-img-overlay {
        padding: 30px 20px 50px 20px;
    }

    .om-img-overlay-text {
        font-size: 26px;
    }

    .video-div {
        width: 95%;
    }

    .sp-footer-site-link-div {
        flex-direction: column;
    }

    .sp-footer-link {
        margin: 5px 0;
    }

    .sp-footer-contact-div,
    .sp-footer-address-div {
        text-align: center;
    }

}

@media (max-width: 1450px) {

    .header-alt-nevin-img-wrapper {
        right: 0;
    }

}

@media (max-width: 1312px) {

    .header-tb-alt {
        padding: 14px 20px;
    }

    .header-alt-contents {
        width: 100%;
    }

    .header-alt-headline {
        font-size: 60px;
        margin-top: 70px;
    }

    .header-alt-button-container {
        flex-direction: column;
    }

    .header-alt-nevin-img-wrapper {
        display: none;
    }

    .om-main-top {
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }

    .om-main-bottom {
        flex-direction: column-reverse;
        margin-top: 80px;
        width: 100%;
    }

    .om-main-top-left {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        display: inline-block;
    }

    .om-main-bottom-right {
        position: relative;
        display: inline-block;
    }

    .om-main-bottom-left {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        justify-content: center;
        text-wrap: nowrap;
        width: 100%;
    }

    .om-main-top-right,
    .om-main-bottom-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .om-os-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        text-align: center;
    }

    .om-os-div {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .om-img-overlay-top {
        position: absolute;
        bottom: 0;
        width: 50%;
        height: 15%;
        padding: 5% 20px;
        background: linear-gradient(to top, #243852, transparent);
        z-index: 2;
        margin-left: 25%;
    }

    .om-img-overlay {
        position: absolute;
        bottom: 0;
        width: 50%;
        height: 15%;
        padding: 7% 20px 0 20px;
        background: linear-gradient(to top, #243852, transparent);
        z-index: 2;
        margin-left: 0;
    }

    .bottom-overlay {
        margin-left: 25%;
    }

    .om-main-top-img {
        width: 50%;
        height: auto;
        margin: auto;
        z-index: 2;
        border-radius: 3px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
        display: block;
        position: relative;
    }

    .om-main-bottom-img {
        width: 50%;
        height: auto;
        margin-left: 25%;
        margin-top: 40px;
        border-radius: 3px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
        display: block;
        z-index: 1;
        position: relative;
    }

    .om-headline {
        font-family: "Playfair Display", serif;
        font-weight: 600;
        font-size: 42px;
        color: #243852;
        margin: auto;
        margin-top: 40px;
        margin-bottom: 30px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
        width: 80%;
        text-align: center;
    }

    .om-blurb {
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        color: #777;
        width: 100%;
        text-align: center;
    }

    .om-img-overlay-text {
        font-family: "Playfair Display", serif;
        font-weight: 600;
        font-size: 24px;
        font-style: italic;
        color: #fafafa;
        margin: 0;
    }

    .om-button {
        height: 50px;
        width: auto;
        max-width: 400px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        font-weight: 600;
        background-color: rgb(36, 56, 82);
        color: #fafafa;
        border: 2px solid rgb(36, 56, 82);
        padding: 10px 15px;
        border-radius: 3px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
        text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.095);
        text-wrap: nowrap;
        letter-spacing: 0.75px;
        margin-top: 50px;
        align-self: center;
    }

    .video-div {
        width: 100%;
        height: auto;
        margin: auto;
        padding: 50px;
        background-color: #fafafa;
        border-radius: 20px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
        margin-bottom: 80px;
        display: flex;
        justify-content: center;
        gap: 40px;
        flex-direction: column;
    }

    .video {
        width: 90%;
        height: auto;
        margin: auto;
        color: #fafafa;
    }

    .video-div-right {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-top: 0;
    }

    .video-blurb {
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        color: #777;
        width: 90%;
        margin: auto;
        text-align: center;
    }

    .sold-pages-meet-the-team {
        display: flex;
        width: 85%;
        height: auto;
        gap: 40px;
        margin: auto;
        flex-direction: column;
        color: #333;
        padding: 80px 0 120px 0;
    }

    .mtt-headline-h1 {
        font-family: "Montserrat", sans-serif;
        font-size: 60px;
        text-align: center;
        color: #333;
        font-weight: 400;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
        text-transform: uppercase;
        width: 80%;
        margin: auto;
    }

    .sp-mtt-bio-div {
        padding: 20px 0 40px 0;
        width: 80%;
        margin: auto;
    }

    .sp-mtt-bio-p {
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        color: #333;
        font-size: 18px;
        padding: 0 20px 30px 20px;
        text-shadow: .5px .5px 1px rgba(0, 0, 0, 0.075);
        text-align: center;
    }

    .sp-mtt-bio-p:last-of-type {
        padding: 0 20px 0 20px;
    }

    .mtt-left-and-right-divs {
        display: flex;
        width: 100%;
        margin: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .sp-meet-team-links-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        height: auto;
        gap: 60px;
    }

    .sp-meet-team-link-wrapper {
        font-family: "Montserrat", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;
        width: 100%;
        height: auto;
    }

    .sp-meet-team-img-wrapper {
        width: 350px;
        height: auto;
    }

    .sp-meet-team-img {
        width: 100%;
        height: auto;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
    }

    .mtt-text-div {
        width: 50%;
        margin-left: 50px;
        display: flex;
        flex-direction: column;
    }

    .mtt-name {
        font-family: "Playfair Display", serif;
        font-size: 36px;
        font-style: italic;
        width: 20%;
        margin-top: 20px;
        font-weight: 600;
    }

    .mtt-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .mtt-button-wrapper {
        margin-top: 40px;
        width: 100%;
        display: flex;
    }

    .mtt-button {
        text-decoration: none;
        color: #333;
        text-transform: uppercase;
        border: 3px solid #333;
        padding: 10px 15px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        width: auto;
    }

    .mtt-button:hover {
        background-color: rgb(36, 56, 82);
        border-color: rgb(36, 56, 82);
        color: #fafafa;
    }

    .mtt-awards-div {
        width: 90%;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: aliceblue;
        padding: 60px 40px 70px 40px;
        border-radius: 10px;
    }

    .sold-pages-h2 {
        font-family: "Montserrat", sans-serif;
        color: #333;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
        font-weight: 400;
        font-size: 45px;
        margin: 0 0 20px 0;
    }

    .sp-mtt-awards-container {
        display: flex;
        flex-direction: row;
        gap: 40px;
        max-width: 90%;
        justify-content: center;
        align-items: center;
        margin: auto;
        background-color: #fff;
        box-shadow: 10px 5px 10px rgba(220, 204, 187, 0.3);
        border-radius: 10px;
        padding: 40px 10px;
        margin-top: 40px;
    }

    .sp-mtt-awards-img-wrap {
        width: 50%;
        height: auto;
    }

    .sp-mtt-awards-img {
        width: 100%;
        height: auto;
    }

    .sp-mtt-awards-text-div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        gap: 40px;
        margin-top: 60px;
        width: 80%;
        font-family: "Montserrat", sans-serif;
    }

    .sp-mtt-awards-text {
        font-weight: 600;
        font-size: 20px;
    }

}

@media (max-width: 1270px) {

    .home-about-p-div {
        flex-direction: column;
        margin: auto;
        text-align: center;
    }

    ul {
        list-style-type: none;
    }

    .mc-outer-container {
        width: 70%;
    }

    .headline-h1,
    .mtt-headline-h1 {
        font-size: 54px;
        width: 80%;
        ;
    }

    .market-comparison-lg {
        display: none;
    }

    .market-comparison {
        display: block;
        height: auto;
        padding: 100px 0;
    }

    .mc-container {
        flex-direction: column;
    }

    .mc-middle::before {
        content: unset;
        position: unset;
        top: unset;
        left: unset;
        width: unset;
        height: unset;
        background: unset;
    }

    .mc-right::before {
        content: unset;
        position: unset;
        top: unset;
        left: unset;
        width: unset;
        height: unset;
        background: unset;
    }

    .mc-stat {
        font-size: 54px;
    }

    .mc-stat-2 {
        font-size: 28px;
    }

    .mc-source {
        font-size: 18px;
    }

    .mc-divider {
        width: 20%;
    }

    .mc-divider-sm {
        height: 2px;
        width: 80%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
        margin: auto;
        margin-top: 50px;
        margin-bottom: 20px;
    }

}

@media (max-width: 1170px) {

    .sold-pages-top-nevin-img-wrapper {
        display: none;
    }

    .header-bottom-bar-md {
        display: none;
    }

    .header-bottom-bar-sm {
        position: absolute;
        bottom: 0;
        height: auto;
        width: 100%;
        padding: 10px;
        background-color: #243852;
        text-align: center;
        z-index: 1;
        display: flex;
        flex-direction: column;
    }

    .sold-pages-top-container {
        width: 100%;
        margin-left: 0;
    }

    .sold-pages-top-contents {
        width: 100%;
    }

    .sold-pages-top-headline {
        width: 100%;
    }

    .header-alt-headline {
        width: 100%;
    }

    .sold-pages-top-button-container {
        margin-top: 20px;
        gap: 30px;
    }

    .home-about-contact-info {
        flex-direction: column;
        margin: auto;
        width: 100%;
    }

    .home-about-p {
        text-align: center;
    }

    .home-about-contact-info-phone-license {
        flex-direction: column;
        margin-top: 10px;
    }

    .hacipl-left,
    .hacipl-right {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .sp-footer-contact-address-div {
        display: flex;
        gap: 40px;
        width: 100%;
        margin: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .sp-footer-contact-div {
        width: 100%;
    }

    .sp-footer-info {
        text-align: center;
    }

}

@media (max-width: 968px) {

    .om-img-overlay,
    .om-img-overlay-top {
        display: none;
    }

    .sp-meet-team-link-wrapper {
        font-family: "Montserrat", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;
        width: 100%;
        height: auto;
        flex-direction: column;
        text-align: center;
    }

    .sp-meet-team-img-wrapper {
        width: 350px;
        height: auto;
    }

    .mtt-text-div {
        width: 100%;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .mtt-name {
        font-family: "Playfair Display", serif;
        font-size: 36px;
        font-style: italic;
        width: 100%;
        margin: auto;
        margin-top: 20px;
        font-weight: 600;
    }

    .mtt-awards-div {
        margin-top: 60px;
    }

    .sp-mtt-awards-container {
        display: flex;
        flex-direction: column;
        gap: 40px;
        max-width: 90%;
        justify-content: center;
        align-items: center;
        margin: auto;
        background-color: #fff;
        box-shadow: 10px 5px 10px rgba(220, 204, 187, 0.3);
        border-radius: 10px;
        padding: 40px 10px;
        margin-top: 40px;
    }

    .sp-mtt-awards-img-wrap {
        width: 30%;
        height: auto;
    }

    .mtt-button-wrapper {
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

}

@media (max-width: 900px) {

    .header-alt-bottom-bar-md {
        display: none;
    }

    .header-alt-bottom-bar-sm {
        height: auto;
        width: 100%;
        padding: 10px;
        background-color: #243852;
        text-align: center;
        z-index: 1;
        display: flex;
        flex-direction: column;
    }

}

@media (max-width: 860px) {

    .header-top-bar {
        padding: 10px 30px;
    }

    .sold-pages-top-headline {
        font-size: 48px;
    }

    .header-alt-headline {
        font-size: 48px;
    }

    .image-slider {
        display: none;
    }

    .home-about-img-wrapper {
        background-color: unset;
        padding: 0;
    }

    .home-about-img {
        width: 100%;
        height: auto;
        display: block;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }

    .headline-h1,
    .home-about-h1,
    .mtt-headline-h1 {
        font-size: 48px;
    }

    .mc-stat {
        font-size: 48px;
    }

    .sp-footer-legal-and-credentials-div {
        flex-direction: column;
        width: 100%;
        margin: auto;
        margin-top: 60px;
        gap: 60px;
        text-align: center;
    }

    .sp-footer-compliance-logos-div {
        width: 100%;
        text-align: center;
    }

    .sp-footer-legal-div {
        width: 100%;
    }

    .sp-footer-legal-p {
        text-align: center;
    }

    .footer-privacy-link {
        text-align: center;
    }

}

@media (max-width: 600px) {

    .sold-pages-top-headline {
        font-size: 40px;
    }

    .header-alt-headline {
        font-size: 40px;
    }

    .sold-pages-top-container {
        margin: auto;
    }

    .header-alt-img-wrapper {
        height: 80vh;
    }

    .header-alt-contents {
        width: 100%;
        margin: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    .header-alt-button-container {
        margin: auto;
        width: 100%;
        margin: auto;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .sold-pages-top-button {
        width: auto;
        padding: 0 10px;
        min-width: unset;
        font-size: 14px;
    }

    .sold-pages-top-bb-text {
        font-size: 14px;
    }

    .bigred {
        font-size: 20px;
    }

    .remax {
        height: 14px;
    }

    .headline-h1 {
        font-size: 42px;
    }

    .home-about-h1 {
        font-size: 42px;
    }

    .home-about-p-div li {
        text-wrap: wrap;
    }

    .mc-outer-container {
        width: 90%;
    }

    .mc-outer-container .headline-h1 {
        width: 100%;
        font-size: 36px;
    }

    .previous-sales-button {
        width: 200px;
        text-wrap: wrap;
    }

    .video-div {
        width: 90%;
        padding: 0;
        height: auto;
        padding: 60px 10px;
    }

    .video {
        width: 90%;
        margin: auto;
    }

    .sp-meet-team-img {
        width: 80%;
        margin: auto;
    }

    .sp-mtt-awards-img-wrap {
        width: 50%;
    }

    .sold-pages-parallax-button {
        text-wrap: wrap;
        font-size: 18px;
    }

    .sp-footer-logo-wrapper {
        width: 250px;
    }

    .footer-br {
        display: block;
    }

    .sp-footer-social-links-div {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;
    }

    .sp-footer-social-wrapper {
        margin: 10px 20px;
    }

}