:root {
    --main-image-width: 600px;
    --main-image-height: 400px;
    /* Adjust height as needed */
    --thumbnail-track-width: 90px;
    --thumbnail-height: 79px;
}

/* * {
    box-sizing: border-box;
}

body {
    background-color: #f5f5f5;
    padding: 20px;
} */

.product-image-carousels {
    display: flex;
    justify-content: center;
}

/**
    Thumbnails carousel
  */
.thumbnails-slider {
    width: var(--thumbnail-track-width);
    padding: 30px 0;
    margin-right: 5px;

    /** Previous/next buttons */
    button {
        height: 30px;
        width: 100%;
        padding: 0;
        margin: 0;

        font-size: 30px;
        color: rgba(0, 0, 0, .7);

        &:not([disabled]):hover {
            background-color: rgba(0, 0, 0, .1);
            color: black;
        }

        &:focus {
            background-color: royalblue;

            .slick-prev-icon,
            .slick-next-icon {
                color: white !important;
            }
        }

        &.slick-prev {
            top: 15px;
            left: 0;

            .slick-prev-icon {
                display: inline-block;
                transform: rotate(90deg);
                color: black;
            }
        }

        &.slick-next {
            bottom: -15px;
            right: 0;
            top: auto;

            .slick-next-icon {
                display: inline-block;
                transform: rotate(90deg);
                color: black;
            }
        }
    }

    /** Single thumbnail */
    .thumbnail-button {
        display: block;
        height: var(--thumbnail-height) !important;

        cursor: pointer;
        border: 0;
        background: none;
        background-color: rgba(0, 0, 0, .4);
        transition: opacity .1s linear;

        &:focus,
        &:hover,
        &[aria-current="true"] {
            img {
                opacity: 1;
                filter: grayscale(0);
            }
        }

        &:focus img {
            outline: 3px dashed white;
            outline-offset: -4px;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            opacity: .3;
            object-fit: cover;
            filter: grayscale(.6);
            transition: all .1s linear;
        }
    }
}


/**
    Main image carousel
  */
.main-image-slider {
    position: relative;
    width: var(--main-image-width);
    height: var(--main-image-height);
    margin-bottom: 1px;

    /** Previous/next buttons */
    button {
        z-index: 1;

        &.slick-prev {
            left: 10px;

            .slick-prev-icon {
                opacity: 1;
            }
        }

        &.slick-next {
            right: 10px;

            .slick-next-icon {
                opacity: 1;
            }
        }
    }

    /** Slides */
    .slick-slide {
        width: var(--main-image-width);

        .image-link {
            height: var(--main-image-height);
            overflow: hidden;
            transition: outline .1s linear;

            &:focus {
                outline: 4px dashed white;
                outline-offset: -7px;
            }

            img {
                display: block;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}


/**
    Demo only
  */
.note {
    text-align: center;
    font-size: 14px;
    width: var(--main-image-width);
    padding: 0 20px;
    margin: 0 auto;
    opacity: .8;

    a {
        color: black;
        font-weight: bold;
    }
}