.pp-page-container {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 160px;
    margin-bottom: 100px;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pp-headline-h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 60px;
    font-weight: 400;
    text-transform: uppercase;
    color: #333;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    text-align: center;
    margin-bottom: 60px;
}

.pp-headline-h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #333;
    margin-bottom: 20px;
}

.pp-paragraph {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
}

.pp-paragraph-bold {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
}

.pp-list {
    margin-left: 100px;
    margin-bottom: 20px;
}

.pp-list-li {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #333;
}

.ppci {
    margin-bottom: 0;
    margin-left: 100px;
}

.pplp {
    margin-top: 20px;
}

.pp-home-link {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #243852;
    text-decoration: none;
    font-weight: 600;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    text-align: center;
    margin-top: 40px;
    text-transform: uppercase;
}

.sp-footer-container {
    background-color: rgb(36, 56, 82);
    font-family: "Montserrat", sans-serif;
    color: #fafafa;
    padding: 80px;
    width: 100%;
    height: auto;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sp-footer-logo-wrapper {
    width: 300px;
    height: auto;
    margin-bottom: 80px;
}

.sp-footer-logo {
    width: 100%;
    height: auto;
}

.sp-footer-site-link-div {
    display: flex;
    width: 100%;
    text-align: center;
    margin: 0 auto 80px auto;
    gap: 20px;
}

.sp-footer-link {
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    text-wrap: nowrap;
    margin: 5px 10px;
    color: #fafafa;
    font-weight: 600;
}

.sp-footer-contact-address-div {
    display: flex;
    gap: 80px;
    width: 60%;
    margin: auto;
}

.sp-footer-contact-div,
.sp-footer-address-div {
    width: auto;
    display: flex;
    flex-direction: column;
    width: 50%;
}

.footer-br {
    display: none;
}

.sp-footer-label {
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.sp-footer-info-bold {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

.sp-footer-info {
    font-size: 18px;
}

.sp-footer-social-links-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}

.sp-footer-social-links {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sp-footer-social-wrapper {
    display: flex;
    height: 22px;
    width: 22px;
    margin: 10px 30px;
}

.sp-footer-social-icon {
    color: #fafafa;
    height: 28px;
    width: 28px;
    cursor: pointer;
}

.sp-footer-legal-and-credentials-div {
    display: flex;
    margin-top: 80px;
}

.sp-footer-compliance-logos-div {
    width: 150px;
    height: auto;
    left: 0;
    width: 50%;
}

.sp-footer-compliance-logos {
    width: 150px;
    height: auto;
}

.sp-footer-legal-div {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
    right: 0;
    justify-content: right;
    width: 50%;
}

.sp-footer-legal-p {
    font-size: 12px;
    color: #fafafa;
    text-align: right;
}

.footer-privacy-link {
    font-size: 12px;
    color: #9cd8eb;
    text-decoration: none;
    text-align: right;
}

@media (max-width: 1525px) {

    .sp-footer-site-link-div {
        flex-direction: column;
    }

    .sp-footer-link {
        margin: 5px 0;
    }

    .sp-footer-contact-div,
    .sp-footer-address-div {
        text-align: center;
    }

}

@media (max-width: 1170px) {

    .sp-footer-contact-address-div {
        display: flex;
        gap: 40px;
        width: 100%;
        margin: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .sp-footer-contact-div {
        width: 100%;
    }

    .sp-footer-info {
        text-align: center;
    }

}

@media (max-width: 860px) {

    .sp-footer-legal-and-credentials-div {
        flex-direction: column;
        width: 100%;
        margin: auto;
        margin-top: 60px;
        gap: 60px;
        text-align: center;
    }

    .sp-footer-compliance-logos-div {
        width: 100%;
        text-align: center;
    }

    .sp-footer-legal-div {
        width: 100%;
    }

    .sp-footer-legal-p {
        text-align: center;
    }

    .footer-privacy-link {
        text-align: center;
    }

}

@media (max-width: 600px) {

    .sp-footer-logo-wrapper {
        width: 250px;
    }

    .footer-br {
        display: block;
    }

    .sp-footer-social-links-div {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;
    }

    .sp-footer-social-wrapper {
        margin: 10px 20px;
    }

}