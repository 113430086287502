.testimonials-component-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: auto;
}

.tc-testimonial-div {
    width: 100%;
    padding: 80px 0 120px 0;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tc-headline-h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 60px;
    text-align: center;
    color: #333;
    font-weight: 400;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
    text-transform: uppercase;
}

.tc-slideshow {
    width: 80%;
    margin: auto;
    margin-top: 80px;
}

.testimonial-slide {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 100px;
}

.tc-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33.33%;
}

.tc-left-right-container {
    display: flex;
    gap: 70px;
    justify-content: center;
    align-items: center;
}

.tc-slide-icon {
    width: 100px;
    height: 100px;
    background-color: rgb(36, 56, 82);
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
}

.tc-slide-icon-span {
    font-family: "Playfair Display", serif;
    color: aliceblue;
    font-size: 100px;
    width: 70%;
}

.tc-right {
    width: 30%;
}

.what-my-clients {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: rgb(36, 56, 82);
    text-transform: uppercase;
    font-size: 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
}

.tc-text-p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: rgb(36, 56, 82);
    font-size: 20px;
    margin-top: 60px;
    margin-bottom: 40px;
    height: 250px;
    text-shadow: .75px .75px 1px rgba(0, 0, 0, 0.075);
}

.tc-name {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: rgb(36, 56, 82);
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
}

.tc-review-source {
    margin-left: 10px;
    vertical-align: middle;
}

.tc-review-source-icon {
    height: 25px;
    width: auto;
}

.tc-bottom-div {
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.tc-button {
    border: 3px solid rgb(36, 56, 82);
    border-radius: 3px;
    width: 450px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: rgb(36, 56, 82);
    text-transform: uppercase;
    text-decoration: none;
    text-wrap: nowrap;
    font-size: 20px;
    text-align: center;
    padding: 10px 15px;
    transition: all .3s ease;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.075);
}

.tc-button:hover {
    background-color: rgb(36, 56, 82);
    color: aliceblue;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
}

@media (max-width: 1500px) {

    .tc-text-p {
        height: 350px;
    }

}

@media (max-width: 1300px) {

    .tc-testimonial-div {
        align-items: center;
    }

    .tc-slideshow {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .testimonial-slide {
        flex-direction: column;
        width: 80%;
        gap: 100px;
        margin: auto;
    }

    .tc-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .tc-left-right-container {
        display: flex;
        gap: 70px;
        justify-content: center;
        align-items: center;
    }

    .tc-slide-icon {
        width: 100px;
        height: 100px;
        background-color: rgb(36, 56, 82);
        border-radius: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
    }

    .tc-slide-icon-span {
        font-family: "Playfair Display", serif;
        color: aliceblue;
        font-size: 100px;
        width: 70%;
    }

    .tc-right {
        width: 30%;
    }

    .what-my-clients {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        color: rgb(36, 56, 82);
        text-transform: uppercase;
        font-size: 20px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
    }

    .tc-text-p {
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        color: rgb(36, 56, 82);
        font-size: 20px;
        margin-top: 60px;
        margin-bottom: 40px;
        height: auto;
        text-shadow: .75px .75px 1px rgba(0, 0, 0, 0.075);
    }

    .tc-name {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        color: rgb(36, 56, 82);
        text-transform: uppercase;
        text-align: center;
        font-size: 18px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
    }

    .tc-review-source {
        margin-left: 10px;
        vertical-align: middle;
    }

    .tc-review-source-icon {
        height: 25px;
        width: auto;
    }

    .tc-bottom-div {
        margin-top: 80px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    .tc-button {
        border: 3px solid rgb(36, 56, 82);
        border-radius: 3px;
        width: 450px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        color: rgb(36, 56, 82);
        text-transform: uppercase;
        text-decoration: none;
        text-wrap: nowrap;
        font-size: 20px;
        text-align: center;
        padding: 10px 15px;
        transition: all .3s ease;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
        box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.075);
    }

    .tc-button:hover {
        background-color: rgb(36, 56, 82);
        color: aliceblue;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.075);
    }

}

@media (max-width: 1270px) {

    .tc-headline-h1 {
        font-size: 54px;
    }

}

@media (max-width: 860px) {

    .tc-headline-h1 {
        font-size: 48px;
    }

}

@media (max-width: 600px) {

    .tc-button {
        width: 300px;
        font-size: 16px;
        text-align: center;
    }

}